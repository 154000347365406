<div class="input-container">
  <app-input [label]="label" [debounceTime]="0" #password2 [formControl]="control"></app-input>
  <button class="btn-icon vs__copy"
          (click)="copyValue(password2)"
          type="button"
          pTooltip="Click to copy"
          tooltipZIndex="999999" tooltipPosition="top">
    <i class="vs_icon vs_copy" aria-hidden="true"></i>
  </button>
</div>

<span class="info_text">
  {{infoText}}
</span>
