<ng-container *ngIf="config">
  <div class="modal-header">
    <h4 [className]="config.type">{{ config.title }}</h4>
    <button (click)="modal.dismiss()" class="close" type="button">&times;</button>
  </div>
  <div class="modal-body">
    <span *ngIf="message">{{config.content}}</span>
    <ng-container *ngIf="template" [ngTemplateOutlet]="template"></ng-container>
  </div>
</ng-container>
