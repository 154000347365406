<div class="form-group">
    <label>{{label}}</label>
    <select class="form-control no-shadow white-background"
            [ngClass]="[selectElementClass]"
            [class.bold]="bold"
            [formControl]="control!!">
        
        <ng-container *ngIf="defaultValue">
            <option [value]="defaultValue">{{defaultValue}}</option>
        </ng-container>

        <ng-container *ngIf="fieldToShow; else primitiveType">
            <ng-container *ngFor="let option of options">
                <option [ngValue]="option">{{option[fieldToShow]}}{{suffix ? ' ' + suffix : ''}}</option>
            </ng-container>
        </ng-container>

        <ng-template #primitiveType>
            <ng-container *ngFor="let option of options">
                <option [value]="option">{{option}}{{suffix ? ' ' + suffix : ''}}</option>
            </ng-container>
        </ng-template>

    </select>
    <span class="error-msg"
          *ngIf="control?.invalid && (control?.dirty || control?.touched) || ( control?.errors)">

                                <span *ngIf="control?.errors?.required ">
                                    {{errorMessage}}
                                </span>

    </span>
</div>
