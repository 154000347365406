import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserAuthenticatedGuard } from './shared/guards/user-authenticated-guard';
import { UserNotAuthenticatedGuard } from './shared/guards/user-not-authenticated-guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(`src/app/core/core.module`).then(module => module.CoreModule),
    canActivate: [UserAuthenticatedGuard]
  },
  {
    path: 'login',
    loadChildren: () => import(`src/app/login/login.module`).then(module => module.LoginModule),
    canActivate: [UserNotAuthenticatedGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
