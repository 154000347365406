<!-- Modal Header -->
<div class="modal-header">
  <h4>API Password Has Been Reset</h4>
  <button type="button" class="close" (click)="closeModal()">×</button>
</div>

<!-- Modal body -->
<div class="modal-body">
  <div class="mb-0">
    <app-copy-input [label]="'NEW API PASSWORD'"
                    [infoText]="'Please make a note of this password as this won’t be displayed again once you leave this page.'"
                    [formControl]="passwordControl">
    </app-copy-input>
  </div>
</div>
