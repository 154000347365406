<div class="mic-container d-flex justify-content-center align-items-center">
  <div class="mic-wrapper">
    <div class="vs_wave wave1" [class.on]="listening"></div>
    <div class="vs_wave wave2" [class.on]="listening"></div>
    <div class="vs_wave wave3" [class.on]="listening"></div>
    <div class="vs_wave wave4" [class.on]="listening"></div>
    <button class="mic"
            [class.white]="whiteBackground"
            [class.larger]="largerIcon"
            [class.processing]="processing"
            [class.disabled]="disabled"
            [disabled]="disabled"
            (click)="onMicrophoneClick()">
      <ng-container *ngIf="whiteBackground; then orangeIcon; else whiteIcon"></ng-container>

      <ng-template #whiteIcon>
        <img src="/assets/images/voice-white.svg" class="pump-animation" alt="voice_icon" title="voice_icon" />
      </ng-template>
      <ng-template #orangeIcon>
        <img src="/assets/images/voice-orange-icon.svg" class="pump-animation" alt="voice_icon" title="voice_icon" />
      </ng-template>
    </button>
  </div>
</div>
