<ng-container *ngIf="values && values.length > 0; else noData">
    <div *ngIf="values"
         class="vs-table" [class.sticky-header]="stickyHeader" [class.sticky-first-row]="stickyFirstRow"
         [ngStyle]="{'max-height': maxHeightPx + 'px'}">
        <div class="vs-table-container" [ngStyle]="{'max-height': (maxHeightPx - MAIN_TABLE_BOTTOM_SPACE_PX) + 'px'}">
            <table>
                <thead>
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                </thead>
                <tbody>
                <ng-template ngFor let-value let-i="index" [ngForOf]="values">
                    <ng-container
                        *ngTemplateOutlet="valueTemplate; context: { $implicit: value, index: i, page: page }"
                    ></ng-container>
                </ng-template>
                </tbody>
            </table>
        </div>
        <div class="d-flex justify-content-between mt-3" *ngIf="pagination">
            <div class="mt-23px">
                <ngb-pagination
                        [collectionSize]="totalCount!!"
                        [(page)]="page"
                        [pageSize]="pageSize!!"
                        [maxSize]="4"
                        [rotate]="true"
                        [ellipses]="true"
                        (pageChange)="handlePageChange($event)"
                ></ngb-pagination>
            </div>

            <div>
                <app-dropdown (onValueChange)="handlePageSizeChange($event)"
                              [options]="pageSizes"
                              [selectedValue]="pageSize"
                              suffix="rows per page"
                              label="Page size">

                </app-dropdown>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #noData>
    <div class="text-center vb-color font-weight-bold">No data available</div>
</ng-template>
