import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit, OnDestroy {

    @Output()
    onValueChange = new EventEmitter<any>();

    @Input()
    control: FormControl | undefined;

    @Input()
    options: any[] | undefined;

    @Input()
    suffix: string | undefined;

    @Input()
    label: string | undefined;

    @Input()
    selectedValue: any | undefined;

    @Input()
    fieldToShow: string | undefined;

    @Input()
    defaultValue: any;

    @Input()
    bold = false;

    @Input()
    selectElementClass  = '';

    private formChangeSubscription: Subscription | undefined;

    errorMessage = 'error message';

    constructor() {
    }

    ngOnInit(): void {
        if (!this.control) {
            this.control = new FormControl(this.selectedValue);
        }
        this.formChangeSubscription = this.control.valueChanges
            .subscribe(it => this.onValueChange.emit(it));
    }

    ngOnDestroy(): void {
        this.formChangeSubscription?.unsubscribe();
    }
}
