/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface FeatureFlag { 
    feature?: FeatureFlag.FeatureEnum;
    enabled?: boolean;
}
export namespace FeatureFlag {
    export type FeatureEnum = 'PI_DASHBOARD' | 'AUDIT_LOGS' | 'DELETE_USER_ENROLLMENT' | 'CLIENT_MANAGEMENT' | 'USER_MANAGEMENT' | 'VOICE_LOGIN' | 'ONW_CLIENT_SERVER_PASSWORD_RESET';
    export const FeatureEnum = {
        PIDASHBOARD: 'PI_DASHBOARD' as FeatureEnum,
        AUDITLOGS: 'AUDIT_LOGS' as FeatureEnum,
        DELETEUSERENROLLMENT: 'DELETE_USER_ENROLLMENT' as FeatureEnum,
        CLIENTMANAGEMENT: 'CLIENT_MANAGEMENT' as FeatureEnum,
        USERMANAGEMENT: 'USER_MANAGEMENT' as FeatureEnum,
        VOICELOGIN: 'VOICE_LOGIN' as FeatureEnum,
        ONWCLIENTSERVERPASSWORDRESET: 'ONW_CLIENT_SERVER_PASSWORD_RESET' as FeatureEnum
    };
}
