/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserEnrolCheckResponse { 
    outcome: UserEnrolCheckResponse.OutcomeEnum;
    numberModelUpdates: number;
}
export namespace UserEnrolCheckResponse {
    export type OutcomeEnum = 'KNOWN_USER_ACTIVE' | 'UNKNOWN_USER';
    export const OutcomeEnum = {
        KNOWNUSERACTIVE: 'KNOWN_USER_ACTIVE' as OutcomeEnum,
        UNKNOWNUSER: 'UNKNOWN_USER' as OutcomeEnum
    };
}
