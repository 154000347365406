import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit, OnDestroy {
    private subscription: Subscription | undefined;

    @Output()
    onInputValueChanged = new EventEmitter<boolean>();

    @Input()
    control: FormControl | undefined;

    @Input()
    label: string | undefined;

    constructor() {
    }

    ngOnInit(): void {
        // If no control is passed the dev wants to use an emitter to receive the value
        // we are still gonna use a form control with a subscription to manage it's state
        if (!this.control) {
            this.control = new FormControl();
            this.subscription = this.control.valueChanges
                .subscribe(it => this.handleOnFormControlChange(it));
        }

    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    handleOnFormControlChange(checked: boolean): void {
        this.onInputValueChanged.emit(checked);
    }
}
