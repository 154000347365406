<div class="form-group">
  <label>{{label}}</label>
  <ng-container *ngIf="fieldPrefix !== undefined; then inputGroup; else regular"></ng-container>
  <ng-content select=".error-msg"></ng-content>
</div>

<ng-template #regular>
  <input class="form-control" [type]="type" value="" [placeholder]="placeholder" [formControl]="control" #inputElement
         (keyup.enter)="onKeyEnter()" (blur)="onTouched()"/>
</ng-template>

<ng-template #inputGroup>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">
        {{ fieldPrefix }}
      </span>
    </div>
    <ng-container *ngTemplateOutlet="regular"></ng-container>
  </div>
</ng-template>
