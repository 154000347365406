<button #p="ngbPopover" [autoClose]="'outside'" [ngbPopover]="popContent" [popoverTitle]="popTitle"
        [ngClass]="['btn', btnClass]" [disabled]="loading">
  <ng-content select="[btn-icon]"></ng-content>
  {{btnText}}
  <vs-icon *ngIf="loading" svgSource="/assets/images/spinner.svg"></vs-icon>
</button>

<ng-template #popTitle>
  <ng-container *ngIf="confirmTextTemplate; then template; else text"></ng-container>

  <ng-template #template>
    <ng-container *ngTemplateOutlet="confirmTextTemplate"></ng-container>
  </ng-template>
  <ng-template #text>{{confirmText}}</ng-template>
</ng-template>

<ng-template #popContent>
  <div class="text-center">
    <button (click)="p.close(); onConfirm()" class="btn btn-success mr-1">{{confirmBtnText}}</button>
    <button (click)="p.close()" class="btn btn-danger">{{cancelBtnText}}</button>
  </div>
</ng-template>
