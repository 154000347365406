/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserCount { 
    id?: number;
    entryDatetime?: string;
    account?: string;
    otherData?: string;
    uniqueNumUsers?: number;
    numModels?: number;
    numEnrolls?: number;
    numDeletes?: number;
    uniqueUsersAdded?: number;
    uniqueUsersDeleted?: number;
}
