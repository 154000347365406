import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UnsubscribableComponent } from '../../api/unsubscribable-component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends UnsubscribableComponent implements OnInit, ControlValueAccessor {

  @Input()
  customErrorMessageTemplate = false;

  @Input()
  label: string | undefined;

  @Input()
  type = 'text';

  @Input()
  placeholder = '';

  @Input()
  debounceTime = 0;

  @Input()
  fieldPrefix: string | undefined;

  @Output()
  onInputValueChanged = new EventEmitter<string>();

  @Output()
  keyEnter = new EventEmitter<void>();

  @ViewChild('inputElement')
  inputElement: ElementRef | null = null;

  readonly control = new FormControl();

  private onChange: (value: string) => void = () => {
  }

  onTouched: () => void = () => {
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public writeValue(obj: any): void {
    this.control.setValue(obj, { emitEvent: false });
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.debounceTime)
      )
      .subscribe(it => {
        this.handleOnFormControlChange(it);
        this.onChange(it);
      });
  }

  handleOnFormControlChange(text: string): void {
    this.onInputValueChanged.emit(text);
  }

  onKeyEnter(): void {
    this.keyEnter.emit();
  }
}

export interface DropdownTextValue {
  key?: string;
  value?: string;
}
