export * from './auditLogs.service';
import { AuditLogsService } from './auditLogs.service';
export * from './client.service';
import { ClientService } from './client.service';
export * from './featureFlags.service';
import { FeatureFlagsService } from './featureFlags.service';
export * from './kpi.service';
import { KpiService } from './kpi.service';
export * from './logging.service';
import { LoggingService } from './logging.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './voice.service';
import { VoiceService } from './voice.service';
export const APIS = [AuditLogsService, ClientService, FeatureFlagsService, KpiService, LoggingService, UserService, VoiceService];
