<div [ngClass]="alertClass">
  <div class="full__screen__right d-flex justify-content-center alert_container align-items-center">
    <div class="vs__notification">
      <h2>{{alertTitle}}</h2>
      <div class="vs__icon">
        <span><i [class]="['vs__alert_icon', iconClass]" aria-hidden="true"></i></span>
      </div>
      <p [innerHTML]="alertMessage"></p>
      <div class="vs__return">
        <a (click)="onClearAlert()" class="vs__return_action pointer">{{ buttonText }}</a>
      </div>
    </div>
  </div>
</div>
