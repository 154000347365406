/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AuditLogModel { 
    activitySessionId?: number;
    activityType?: string;
    account?: string;
    context?: string;
    identifier?: string;
    time?: Date;
    duration?: number;
    result?: string;
    code?: string;
    score?: number;
    info?: string;
    agentId?: string;
    ticketId?: string;
}
