import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { LoginResponse } from '../domain/login-response';
import { environment } from '../../../environments/environment';
import { PiCategory } from '../domain/pi-category';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FeatureFlagsService } from '../../shared/services/feature-flags.service';

const apiUri = '/api/pi';
const fiveMinutesInMillis = 300_000;

@Injectable({
    providedIn: 'root'
})
export class PiService implements OnDestroy {
    private refreshTokenSubscription: Subscription | undefined;
    private loginSubject = new BehaviorSubject<string | undefined>(undefined);

    constructor(
      private readonly httpClient: HttpClient,
      private readonly featureFlagsService: FeatureFlagsService
    ) {
    }

    ngOnDestroy(): void {
        this.refreshTokenSubscription?.unsubscribe();
        this.loginSubject.complete();
    }

    login(): Observable<LoginResponse | null> {
      return this.featureFlagsService.isPiDashboardEnabled()
        .pipe(
          switchMap(isEnabled => {
            if (!isEnabled) {
              return of(null);
            }

            return this.httpClient.get<LoginResponse>(apiUri)
              .pipe(
                tap(it => {
                  this.loginSubject.next(it.token);
                  this.initializeRefreshTokenInterval();
                })
              );
          })
        );
    }

    getEmbedToken(): Observable<string> {
        return this.loginSubject.asObservable()
            .pipe(
                map(it =>
                    new HttpHeaders()
                        .set('Authorization', `bearer ${it}`)),
                switchMap(it => this.httpClient.get(`${environment.PI_API_URL}/pi/auth/embeddedTokenLogin`, {
                    headers: it,
                    responseType: 'text',
                    observe: 'body',
                    withCredentials: true
                }))
            );
    }

    getCategories(): Observable<PiCategory[]> {
        return this.loginSubject.asObservable()
            .pipe(
                map(it => new HttpHeaders()
                    .set('Authorization', `bearer ${it}`)),
                switchMap(it => this.httpClient.get<PiCategory[]>(`${environment.PI_API_URL}/pi/api/v2/categories`,
                    {headers: it, })));
    }

    private initializeRefreshTokenInterval(): void {
        // Each five minutes issue a request to refresh the login token
        this.refreshTokenSubscription = interval(fiveMinutesInMillis)
            .pipe(
                switchMap(_ => this.loginSubject.asObservable()),
                // @ts-ignore info: The compiler doesn't support rxjs operators
                filter(it => it !== null && it !== undefined),
                switchMap((it: string) => this.refreshToken(it))
            )
            .subscribe();
    }

    private refreshToken(it: string): Observable<void> {
        const headers = new HttpHeaders()
          .set('Authorization', `bearer ${it}`);
        return this.httpClient.post<void>(`${environment.PI_API_URL}/pi/api/v2/tokens/keepAlive`, undefined, {headers});
    }
}
