/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuditLogDetails } from '../model/auditLogDetails';
import { AuditLogPageResult } from '../model/auditLogPageResult';
import { ErrorResponse } from '../model/errorResponse';
import { HasAudio } from '../model/hasAudio';
import { StringArray } from '../model/stringArray';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuditLogsService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download audit logs as CSV
     * 
     * @param fromDate Start date to query the audit logs, inclusive
     * @param toDate End date to query the audit logs, inclusive
     * @param sortField Field by which results should be sorted
     * @param sortDirection DIrection by which results should be sorted
     * @param criteria Criteria to search by
     * @param identifier User identifier
     * @param activityType Activity type
     * @param criteriaValue The value of the selected criteria
     * @param adminEventsOnly Search for admin events only
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadCSVAuditLogs(fromDate: Date, toDate: Date, sortField: string, sortDirection: 'ASC' | 'DESC', criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public downloadCSVAuditLogs(fromDate: Date, toDate: Date, sortField: string, sortDirection: 'ASC' | 'DESC', criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public downloadCSVAuditLogs(fromDate: Date, toDate: Date, sortField: string, sortDirection: 'ASC' | 'DESC', criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public downloadCSVAuditLogs(fromDate: Date, toDate: Date, sortField: string, sortDirection: 'ASC' | 'DESC', criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling downloadCSVAuditLogs.');
        }

        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling downloadCSVAuditLogs.');
        }

        if (sortField === null || sortField === undefined) {
            throw new Error('Required parameter sortField was null or undefined when calling downloadCSVAuditLogs.');
        }

        if (sortDirection === null || sortDirection === undefined) {
            throw new Error('Required parameter sortDirection was null or undefined when calling downloadCSVAuditLogs.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (criteria !== undefined && criteria !== null) {
            queryParameters = queryParameters.set('criteria', <any>criteria);
        }
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }
        if (activityType !== undefined && activityType !== null) {
            queryParameters = queryParameters.set('activityType', <any>activityType);
        }
        if (criteriaValue !== undefined && criteriaValue !== null) {
            queryParameters = queryParameters.set('criteriaValue', <any>criteriaValue);
        }
        if (adminEventsOnly !== undefined && adminEventsOnly !== null) {
            queryParameters = queryParameters.set('adminEventsOnly', <any>adminEventsOnly);
        }
        if (sortField !== undefined && sortField !== null) {
            queryParameters = queryParameters.set('sortField', <any>sortField);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('sortDirection', <any>sortDirection);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/audit-logs/files/csv`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all audit-logs type ids
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getActivityLogIdEnum(observe?: 'body', reportProgress?: boolean): Observable<StringArray>;
    public getActivityLogIdEnum(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringArray>>;
    public getActivityLogIdEnum(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringArray>>;
    public getActivityLogIdEnum(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StringArray>(`${this.basePath}/audit-logs/type-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all audit-logs activity types
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAuditLogActivityTypes(observe?: 'body', reportProgress?: boolean): Observable<StringArray>;
    public getAuditLogActivityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StringArray>>;
    public getAuditLogActivityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StringArray>>;
    public getAuditLogActivityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StringArray>(`${this.basePath}/audit-logs/activity-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the audit log has audio
     * 
     * @param sentinelKey The sentinel id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hasAudio(sentinelKey: string, observe?: 'body', reportProgress?: boolean): Observable<HasAudio>;
    public hasAudio(sentinelKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HasAudio>>;
    public hasAudio(sentinelKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HasAudio>>;
    public hasAudio(sentinelKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sentinelKey === null || sentinelKey === undefined) {
            throw new Error('Required parameter sentinelKey was null or undefined when calling hasAudio.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<HasAudio>(`${this.basePath}/audit-logs/${encodeURIComponent(String(sentinelKey))}/hasAudio`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Query for an audit log by id
     * 
     * @param id The id of the audit log
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryAuditLogById(id: number, observe?: 'body', reportProgress?: boolean): Observable<AuditLogDetails>;
    public queryAuditLogById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuditLogDetails>>;
    public queryAuditLogById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuditLogDetails>>;
    public queryAuditLogById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling queryAuditLogById.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AuditLogDetails>(`${this.basePath}/audit-logs/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Query audit logs
     * 
     * @param fromDate Start date to query the audit logs, inclusive
     * @param toDate End date to query the audit logs, inclusive
     * @param sortField Field by which results should be sorted
     * @param sortDirection DIrection by which results should be sorted
     * @param page The page number
     * @param size The page size number
     * @param criteria Criteria to search by
     * @param identifier User identifier
     * @param activityType Activity type
     * @param criteriaValue The value of the selected criteria
     * @param adminEventsOnly Search for admin events only
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryAuditLogs(fromDate: Date, toDate: Date, sortField: 'ACTIVITY_TYPE' | 'REQUESTED_BY' | 'USER_IDENTIFIER' | 'START_TIME' | 'DURATION' | 'OUTCOME' | 'ERROR_CODE', sortDirection: 'ASC' | 'DESC', page: number, size: number, criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'body', reportProgress?: boolean): Observable<AuditLogPageResult>;
    public queryAuditLogs(fromDate: Date, toDate: Date, sortField: 'ACTIVITY_TYPE' | 'REQUESTED_BY' | 'USER_IDENTIFIER' | 'START_TIME' | 'DURATION' | 'OUTCOME' | 'ERROR_CODE', sortDirection: 'ASC' | 'DESC', page: number, size: number, criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuditLogPageResult>>;
    public queryAuditLogs(fromDate: Date, toDate: Date, sortField: 'ACTIVITY_TYPE' | 'REQUESTED_BY' | 'USER_IDENTIFIER' | 'START_TIME' | 'DURATION' | 'OUTCOME' | 'ERROR_CODE', sortDirection: 'ASC' | 'DESC', page: number, size: number, criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuditLogPageResult>>;
    public queryAuditLogs(fromDate: Date, toDate: Date, sortField: 'ACTIVITY_TYPE' | 'REQUESTED_BY' | 'USER_IDENTIFIER' | 'START_TIME' | 'DURATION' | 'OUTCOME' | 'ERROR_CODE', sortDirection: 'ASC' | 'DESC', page: number, size: number, criteria?: string, identifier?: string, activityType?: string, criteriaValue?: string, adminEventsOnly?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling queryAuditLogs.');
        }

        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling queryAuditLogs.');
        }

        if (sortField === null || sortField === undefined) {
            throw new Error('Required parameter sortField was null or undefined when calling queryAuditLogs.');
        }

        if (sortDirection === null || sortDirection === undefined) {
            throw new Error('Required parameter sortDirection was null or undefined when calling queryAuditLogs.');
        }

        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling queryAuditLogs.');
        }

        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling queryAuditLogs.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (criteria !== undefined && criteria !== null) {
            queryParameters = queryParameters.set('criteria', <any>criteria);
        }
        if (identifier !== undefined && identifier !== null) {
            queryParameters = queryParameters.set('identifier', <any>identifier);
        }
        if (activityType !== undefined && activityType !== null) {
            queryParameters = queryParameters.set('activityType', <any>activityType);
        }
        if (criteriaValue !== undefined && criteriaValue !== null) {
            queryParameters = queryParameters.set('criteriaValue', <any>criteriaValue);
        }
        if (adminEventsOnly !== undefined && adminEventsOnly !== null) {
            queryParameters = queryParameters.set('adminEventsOnly', <any>adminEventsOnly);
        }
        if (sortField !== undefined && sortField !== null) {
            queryParameters = queryParameters.set('sortField', <any>sortField);
        }
        if (sortDirection !== undefined && sortDirection !== null) {
            queryParameters = queryParameters.set('sortDirection', <any>sortDirection);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AuditLogPageResult>(`${this.basePath}/audit-logs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Wav audio
     * 
     * @param sentinelKey The sentinel id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wavAudio(sentinelKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wavAudio(sentinelKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wavAudio(sentinelKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wavAudio(sentinelKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sentinelKey === null || sentinelKey === undefined) {
            throw new Error('Required parameter sentinelKey was null or undefined when calling wavAudio.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/wav'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/audit-logs/${encodeURIComponent(String(sentinelKey))}/wav`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Zips the audio for the audit log
     * 
     * @param sentinelKey The sentinel id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zipAudio(sentinelKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zipAudio(sentinelKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zipAudio(sentinelKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zipAudio(sentinelKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sentinelKey === null || sentinelKey === undefined) {
            throw new Error('Required parameter sentinelKey was null or undefined when calling zipAudio.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/audit-logs/${encodeURIComponent(String(sentinelKey))}/zippedAudio`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Zips the whole request for the audit log
     * 
     * @param sentinelKey The sentinel id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public zipAuditLog(sentinelKey: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public zipAuditLog(sentinelKey: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public zipAuditLog(sentinelKey: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public zipAuditLog(sentinelKey: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sentinelKey === null || sentinelKey === undefined) {
            throw new Error('Required parameter sentinelKey was null or undefined when calling zipAuditLog.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/zip'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/audit-logs/${encodeURIComponent(String(sentinelKey))}/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
