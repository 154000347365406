import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snakeUpperToCamel',
  pure: true
})
export class SnakeUpperToCamelPipe implements PipeTransform {

  private static firstLetterToUpperCase(input: string): string {
    const firstLetter = input.charAt(0).toUpperCase();
    const rest = input.substring(1, input.length);
    return `${firstLetter}${rest}`;
  }

  transform(value: string | undefined): string | undefined {
    if (!value) {
      return undefined;
    }

    if (value.length === 0) {
      return value;
    }

    const splitSnakeCase = value.toLocaleLowerCase().split('_');

    if (splitSnakeCase.length === 1) {
      return value.charAt(0).toUpperCase() + value.substring(1, value.length).toLowerCase();
    }

    return splitSnakeCase.reduce((acc, val, idx) => {
      if (idx === 1) {
        acc = SnakeUpperToCamelPipe.firstLetterToUpperCase(acc);
      }
      return `${acc} ${SnakeUpperToCamelPipe.firstLetterToUpperCase(val)}`;
    });
  }

}
