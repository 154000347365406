/**
 * Portal application.
 * Portal application documentation. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ArrayOfUser } from '../model/arrayOfUser';
import { ArrayOfUserWithoutRoles } from '../model/arrayOfUserWithoutRoles';
import { ClientExist } from '../model/clientExist';
import { ClientMetadata } from '../model/clientMetadata';
import { ClientPassword } from '../model/clientPassword';
import { ErrorResponse } from '../model/errorResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ClientService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Cancel a deletion of given client.
     * 
     * @param clientName The name of client that needs to be canceled for deletion.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelClientDeletion(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public cancelClientDeletion(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public cancelClientDeletion(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public cancelClientDeletion(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling cancelClientDeletion.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}/cancel-deletion`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check if client with given name exist
     * 
     * @param clientName The name of client that needs to be checked for existance.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkIfClientExist(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<ClientExist>;
    public checkIfClientExist(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientExist>>;
    public checkIfClientExist(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientExist>>;
    public checkIfClientExist(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling checkIfClientExist.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClientExist>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createClient
     * 
     * @param clientName 3 letters client name that need to be created.
     * @param body Create client object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createClient(clientName: string, body: ArrayOfUserWithoutRoles, observe?: 'body', reportProgress?: boolean): Observable<ArrayOfUser>;
    public createClient(clientName: string, body: ArrayOfUserWithoutRoles, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArrayOfUser>>;
    public createClient(clientName: string, body: ArrayOfUserWithoutRoles, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArrayOfUser>>;
    public createClient(clientName: string, body: ArrayOfUserWithoutRoles, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling createClient.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ArrayOfUser>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a client.
     * 
     * @param clientName The name of client that needs to be deleted.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteClient(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteClient(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteClient(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteClient(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling deleteClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Disables a client
     * 
     * @param clientName The name of client that needs to be disabled.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public disableClient(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public disableClient(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public disableClient(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public disableClient(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling disableClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}/disable`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Enables a client
     * 
     * @param clientName The name of client that needs to be enabled.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public enableClient(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public enableClient(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public enableClient(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public enableClient(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling enableClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}/enable`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get metadata for client
     * 
     * @param clientName The name of client to get metadata for.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientMetadata(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<ClientMetadata>;
    public getClientMetadata(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientMetadata>>;
    public getClientMetadata(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientMetadata>>;
    public getClientMetadata(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling getClientMetadata.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ClientMetadata>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}/metadata`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update client server password
     * 
     * @param clientName The name of client.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setNewPasswordForClient(clientName: string, observe?: 'body', reportProgress?: boolean): Observable<ClientPassword>;
    public setNewPasswordForClient(clientName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientPassword>>;
    public setNewPasswordForClient(clientName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientPassword>>;
    public setNewPasswordForClient(clientName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientName === null || clientName === undefined) {
            throw new Error('Required parameter clientName was null or undefined when calling setNewPasswordForClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ClientPassword>(`${this.basePath}/client/${encodeURIComponent(String(clientName))}/reset-server-password`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update client server password for current client
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setNewPasswordForCurrentClient(observe?: 'body', reportProgress?: boolean): Observable<ClientPassword>;
    public setNewPasswordForCurrentClient(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClientPassword>>;
    public setNewPasswordForCurrentClient(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClientPassword>>;
    public setNewPasswordForCurrentClient(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (basicAuth) required
        if (this.configuration.username || this.configuration.password) {
            headers = headers.set('Authorization', 'Basic ' + btoa(this.configuration.username + ':' + this.configuration.password));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<ClientPassword>(`${this.basePath}/client/reset-server-password`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
